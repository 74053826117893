import React from 'react';
import { Helmet } from 'react-helmet';

import favicon from '../assets/app-icon.png';

function SEO() {
  const title = 'GitPigeon — GitHub notifications for your Mac';
  const description =
    'Get notified only when someone mentions you in the comment, on pull request checks (CI/CD) and on code review.';
  const root_url = "https://gitpigeon.com";

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <link rel="icon" href={favicon} />
      <meta name="description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${root_url}/og-image.png`} />
      <meta property="og:url" content={root_url} />
      <meta property="og:image" content={`${root_url}/og-image.png`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
}

export default SEO;
